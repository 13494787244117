.footer{
    height: 120px;
    color: #6A6B76;
    background:#2a2c3b;
}
.wanganbeian-1{
    width:auto;
    margin:0 auto;
    padding:20px 0;
} 
.wanganbeian-2{
    display:inline-block;
    text-decoration:none;
    height:20px;
    line-height:20px;
}
.wanganbeian-3{
    float:left;
}
.wanganbeian-4{
    float:left;
    height:20px;
    line-height:20px;
    margin: 0px 0px 0px 5px;
    color:#939393;
}
